import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container } from 'reactstrap'
import { Helmet } from 'react-helmet'
import Header from '../Components/Header'
import { setIsOpen } from 'Actions/BusinessActions'
// import { isIOS } from 'react-device-detect'

import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'
// import googleStore from '../assets/img/google-play-badge.png'
// import appleStore from '../assets/img/apple-store-badge.png'

class Landing extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      inputFocus: false
    }
  }

  componentDidMount () {
    this.props.setIsOpen(false)
    document.body.classList.toggle('index-page')
  }

  componentWillUnmount () {
    document.body.classList.toggle('index-page')
  }

  render () {
    // const GotoStore = (!isIOS ? 'https://play.google.com/store/apps/details?id=com.heresmyinfo.client' : 'https://apps.apple.com/us/app/heresmyinfo/id1439939855')
    return (
      <>
        <Header />
        <Helmet>
          <title>HeresMyInfo - Contact Sharing</title>
        </Helmet>

        <Container>
          <img className='logo logo1' alt='' src={require('../assets/img/share.png')} />
          <img className='logo logo2' alt='' src={require('../assets/img/share.png')} />
          <img className='logo logo3' alt='' src={require('../assets/img/share.png')} />
          <img className='logo logo4' alt='' src={require('../assets/img/share.png')} />
          <img className='logo logo5' alt='' src={require('../assets/img/share.png')} />
          <img className='logo logo6' alt='' src={require('../assets/img/share.png')} />
          <img className='logo logo7' alt='' src={require('../assets/img/share.png')} />

          <Row>
            <Col>
              {/* <Zoom left>
                <h1 style={{ color: 'white', textAlign: 'center', marginTop: '30px', fontSize: '1.8rem' }}>HeresMyInfo</h1>
              </Zoom> */}
              <Fade left>
                <h3 className='landingTitle' style={{ marginTop: 40, marginBottom: 0 }}>Contact Sharing</h3>
                <h3 className='landingTitle' style={{ marginTop: 0 }}>for the Mobile Age.</h3>
              </Fade>
            </Col>
          </Row>


          

            <Col xs='12'>
              <Zoom left>
                <h6 className='display-5' 
                style={{ marginTop: 10, marginBottom: 0, textAlign: 'center' }}>The new, best way to swap your info.</h6>
              </Zoom>
              <Zoom left>
                <h6 className='display-5' 
                style={{ marginTop: 10, marginBottom: 0, textAlign: 'center' }}>Control what people have, and who has it,</h6>
              </Zoom>
              <Zoom left>
                <h6 className='display-5' 
                style={{ marginTop: 10, marginBottom: 0, textAlign: 'center' }}>even<b style={{ fontStyle: 'italic' }}> after </b>you've shared it.</h6>
              </Zoom>
              <Zoom left>
                <h6 className='display-5' 
                style={{ marginTop: 10, marginBottom: 0, textAlign: 'center' }}>Quick. Easy. Fast. Secure.</h6>
              </Zoom>
              <Zoom left>
                <h6 className='display-4' style={{ marginBottom: 15, fontWeight: '500',marginTop: 0, textAlign: 'center' }}>HeresMyInfo.</h6>
              </Zoom>
              
            
            
    
              <Zoom left>
                <h6 style={{ marginBottom: 0, textAlign: 'center' }} className='display-5'>(...and oh yeah. It's free.)</h6>
              </Zoom>
            </Col>  
              
              {/* <h6 style={{ marginBottom: 0 }} className='display-5'>Sign up for an account today using </h6>
              <h6 className='display-5'>the app on Apple or Android</h6> */}
              {/* <span className='badge google'><img src={googleStore} alt='HeresMyInfo' /></span>
              <span className='badge apple'><img src={appleStore} alt='HeresMyInfo' /></span> */}
           
             <Zoom left>
                <h6 className='display-5' style={{ marginBottom: 0, fontWeight: '500', textAlign: 'center', color: 'blue' }}>COMING SOON</h6>
               </Zoom>
             
              <Zoom left>
                <h6 className='display-6' style={{ marginTop: 20, fontWeight: '200', textAlign: 'center' }}> All rights reserved, HeresMyInfo, Inc. 2020-2024</h6>
               </Zoom>
          
          
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = dispatch => ({
  setIsOpen : () => dispatch(setIsOpen(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(Landing)